import Head from "next/head";
import { Button } from "@/components/Button";
import { CallToAction } from "@/components/CallToAction";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";

export default function Custom404() {
  const pageTitle = "Not Found";
  return (
    <>
      <Head>
        <title>{`${pageTitle} - Govern For California`}</title>
        <meta name="description" content="Page not found" />
      </Head>
      <Header />
      <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
          <main className="text-center sm:flex sm:text-left">
            <p className="font headline text-4xl font-bold tracking-tighter text-brand-text sm:text-5xl">
              404
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="font-headline text-4xl font-bold tracking-tighter text-gray-900 sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className="mt-10 space-x-3 sm:flex sm:border-l sm:border-transparent sm:pl-6">
                <Button href="/" variant="outline">
                  Home
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <CallToAction />
      <Footer />
    </>
  );
}
